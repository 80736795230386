import React, { Component } from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider from 'react-slick'

const Wrapper = styled.div`
  padding: 1% 0 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Header = styled.h1`
  font-size: 60px;
  text-transform: uppercase;
  text-align: center;
  padding: 5% 10% 0% 10%;
  color: white;
  font-weight: bold;
  margin-bottom: 5%;
  @media (max-width: 800px) {
    font-size: 50px;
  }
  @media (max-width: 700px) {
    font-size: 40px;
  }
  @media (max-width: 550px) {
    font-size: 30px;
  }
  @media (max-width: 450px) {
    font-size: 22px;
  }
`

const Img = styled.img`
  height: 20rem;
  margin: auto;
  @media (max-width: 1300px) {
    height: 15rem;
  }
  @media (max-width: 1000px) {
    height: 12rem;
  }
  @media (max-width: 750px) {
    height: 10rem;
  }
  @media (max-width: 650px) {
    height: 8rem;
  }
  @media (max-width: 500px) {
    height: 6rem;
  }
`

class Ribbon extends Component {
  constructor(props) {
    super(props)
    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
  }
  play() {
    this.slider.slickPlay()
  }
  pause() {
    this.slider.slickPause()
  }

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      pauseOnHover: false,
    }
    const { block } = this.props
    return (
      <Wrapper>
        <Header>{block.title}</Header>
        <Slider
          arrows={false}
          ref={slider => (this.slider = slider)}
          {...settings}
        >
          {block.imagegallery.map(i => (
            <div key={i}>
              <Img src={i} />
            </div>
          ))}
        </Slider>
      </Wrapper>
    )
  }
}

Ribbon.propTypes = {
  classes: PropTypes.object,
}

export default Ribbon
