import React from 'react'
// nodejs library to set properties for components
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from 'components/CustomButtons/Button.jsx'

class CTA extends React.Component {
  render() {
    const { block } = this.props
    return (
      <Wrapper style={{ backgroundColor: block.background_color }}>
        <div style={{ width: '80%', margin: 'auto' }}>
          <CtaHeader>Apply as Freelancer</CtaHeader>
          <ColWrap>
            <JourneyColumn>
              <JourneyHeading color={gray}>
                <span>01 / </span>Why?
              </JourneyHeading>
              <JourneyText>
                Work alongside some of the best in the world. Let the work come
                to you. We work with the best brands in the world – cherry pick
                your projects!
              </JourneyText>
            </JourneyColumn>

            <JourneyColumn>
              <JourneyHeading color={gray}>
                <span>02 / </span>Lucrative
              </JourneyHeading>
              <JourneyText>Competitive pay.</JourneyText>
            </JourneyColumn>
            <JourneyColumn>
              <JourneyHeading color={gray}>
                <span>03 / </span>Flexability
              </JourneyHeading>
              <JourneyText>
                Work where you want, when you want for as long as you want.
              </JourneyText>
            </JourneyColumn>

            <JourneyColumn>
              <JourneyHeading color={gray}>
                <span>04 / </span>Network
              </JourneyHeading>
              <JourneyText>Learn, share, grow, network, succeed.</JourneyText>
            </JourneyColumn>
          </ColWrap>
        </div>
        <ButtonWrap>
          <Button
            onClick={() => navigate('/apply')}
            round
            color="success"
            size="lg"
          >
            <ButtonText>{block.button_text}</ButtonText>
          </Button>
        </ButtonWrap>
      </Wrapper>
    )
  }
}

export default CTA

const Wrapper = styled.div`
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1.2rem;
`
const CtaHeader = styled.h1`
  text-align: center;
  font-size: 100px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0 10px;
  @media (max-width: 1275px) {
    font-size: 70px;
  }
  @media (max-width: 890px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 29px;
  }
`
const JourneyColumn = styled.div`
  flex-direction: column;
  width: 20%;
  height: 170px;
  margin: auto;
  @media (max-width: 700px) {
    width: 65%;
    height: 100%;
    padding-bottom: 20px;
  }
`

const JourneyHeading = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 25%;
  color: ${props => props.color};
  span {
    color: white;
    font-weight: normal;
  }
`

const JourneyText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
`

const ColWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  margin-bottom: 5%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: #ff5a00;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 1vh;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  padding: 5% 5%;
  flex-direction: column;
  width: 40%;
  margin: auto;
  @media (max-width: 1000px) {
    width: 45%;
  }
  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
`
const gray = '#232323'
