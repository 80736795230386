import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WhyColumn = styled.div`
  flex-direction: column;
  width: 20%;
  height: 170px;
  margin: auto;
  @media (max-width: 700px) {
    width: 70%;
    height: 100%;
    padding-bottom: 20px;
  }
`

const WhyHeading = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 20px;
  color: ${props => props.color};

  span {
    color: white;
    font-weight: normal;
  }
`

const WhyText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
`
const SectionHeading = styled.h1`
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  font-size: 100px;
  @media (max-width: 1275px) {
    font-size: 70px;
  }
  @media (max-width: 890px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 29px;
  }
`

const SectionSubheading = styled.p`
  color: #ff9000;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`
const Wrapper = styled.div`
  width: 70%;

  @media (max-width: 920px) {
    width: 70%;
  }
  @media (max-width: 640px) {
    width: 80%;
  }
`
const ColWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  line-height: 1.2rem;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const pink = '#ff0066'
const orange = '#ff5a00'
const yellow = '#ff9000'

class Product extends React.Component {
  render() {
    const { block } = this.props
    return (
      <div
        id="why"
        style={{
          backgroundColor: '#232323',
          padding: '5% 0',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Wrapper>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ textAlign: 'center' }}>
              {' '}
              <SectionHeading>
                Why Flexa<span style={{ color: pink }}>t</span>
                <span style={{ color: orange }}>a</span>
                <span style={{ color: yellow }}>l</span>?
              </SectionHeading>
              <SectionSubheading>{block.subtitle}</SectionSubheading>
            </div>

            <ColWrap>
              <WhyColumn>
                <WhyHeading color={pink}>
                  <span>01 / </span>
                  {block.col1.title}
                </WhyHeading>
                <WhyText>
                  We have a network of the best technologists in the world.
                </WhyText>
              </WhyColumn>

              <WhyColumn>
                <WhyHeading color={orange}>
                  <span>02 / </span>
                  {block.col2.title}
                </WhyHeading>
                <WhyText>
                  Our freelancers are proven – we test them, and we verify them
                  with previous hirers.
                </WhyText>
              </WhyColumn>
              <WhyColumn>
                <WhyHeading color={yellow}>
                  <span>03 / </span>
                  {block.col3.title}
                </WhyHeading>
                <WhyText>{block.col3.subtitle}</WhyText>
              </WhyColumn>
              <WhyColumn>
                <WhyHeading color={yellow}>
                  <span>04 / </span>
                  Efficient
                </WhyHeading>
                <WhyText>You can hire specialists quickly.</WhyText>
              </WhyColumn>
            </ColWrap>
          </div>
        </Wrapper>
      </div>
    )
  }
}

Product.propTypes = {
  classes: PropTypes.object,
}

export default Product
