/* eslint-disable */
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from 'components/CustomButtons/Button.jsx'

const Wrapper = styled.div`
  background-color: #ff0066;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1.2rem;
`

const SectionHeader = styled.h1`
  text-align: center;
  font-size: 100px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  @media (max-width: 1275px) {
    font-size: 70px;
  }
  @media (max-width: 890px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 29px;
  }
`

const JourneyColumn = styled.div`
  flex-direction: column;
  width: 20%;
  height: 170px;
  margin: auto;
  @media (max-width: 700px) {
    width: 65%;
    height: 100%;
    padding-bottom: 20px;
  }
`

const JourneyHeading = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  padding-bottom: 25%;
  color: ${props => props.color};
  span {
    color: white;
    font-weight: normal;
  }
`

const JourneyText = styled.p`
  color: white;
  font-size: 14px;
  font-weight: bold;
`

const ColWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  margin-bottom: 5%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: #ff0066;
  margin: 0;

  @media (max-width: 400px) {
    font-size: 1vh;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  padding: 5% 5%;
  flex-direction: column;
  width: 40%;
  margin: auto;
  @media (max-width: 1000px) {
    width: 45%;
    margin-top: 50px;
  }
  @media (max-width: 800px) {
    width: 50%;
    margin-top: 100px;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
`

const pink = '#ff0066'
const orange = '#ff5a00'
const yellow = '#ff9000'

class Project extends React.Component {
  render() {
    const { block, classes, ...rest } = this.props
    return (
      <Wrapper>
        <div style={{ width: '80%', margin: 'auto' }}>
          <SectionHeader>Hire Freelance Talent</SectionHeader>
          <ColWrap>
            <JourneyColumn>
              <JourneyHeading color={yellow}>
                <span>01 / </span>Describe
              </JourneyHeading>
              <JourneyText>
                Tell us what kind of freelance talent you're looking for. Give
                us a short description of what you need (our CTO can help if
                needed).
              </JourneyText>
            </JourneyColumn>

            <JourneyColumn>
              <JourneyHeading color={yellow}>
                <span>02 / </span>Search
              </JourneyHeading>
              <JourneyText>
                We hand pick our best consultants for you – all of them are
                pre-verified, technically, and referenced (normal shortlist
                size: 3 per seat).
              </JourneyText>
            </JourneyColumn>
            <JourneyColumn>
              <JourneyHeading color={yellow}>
                <span>03 / </span>Work
              </JourneyHeading>
              <JourneyText>
                Try them at no cost. Integrate them into your business. Our
                freelancers are proven experts in their field. We check their
                technical skills as well as their communication and competencies
                through a full 360 degree verification process.
              </JourneyText>
            </JourneyColumn>

            <JourneyColumn>
              <JourneyHeading color={yellow}>
                <span>04 / </span>Enjoy
              </JourneyHeading>
              <JourneyText>
                Freelancers then become a part of your team and you can enjoy
                building working relationships with them.
              </JourneyText>
            </JourneyColumn>
          </ColWrap>
        </div>
        <ButtonWrap>
          <Button
            onClick={() => navigate('/hire')}
            round
            color="success"
            size="lg"
          >
            <ButtonText>Hire</ButtonText>
          </Button>
        </ButtonWrap>
      </Wrapper>
    )
  }
}

Project.propTypes = {
  classes: PropTypes.object,
}

export default Project
