import React from 'react'
import Helmet from 'react-helmet'
// eslint-disable-next-line
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.jsx'
// import blocks
import Banner from '../components/blocks/banner'
import Product from '../components/blocks/product'
import Project from '../components/blocks/project'
import CTA from '../components/blocks/cta'
import Ribbon from '../components/blocks/ribbon'
import Contact from '../components/blocks/contact'
import Testimonial from '../components/blocks/testimonial'
import favicon from '../components/favicon.jpg'

class BlocksTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = post.excerpt

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttribute={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${favicon}`,
            },
          ]}
        />
        {post.frontmatter.blocks.map(block => {
          let element
          switch (block.component) {
            case 'banner':
              element = <Banner key={Math.random()} block={block} />
              break
            case 'product':
              element = <Product key={Math.random()} block={block} />
              break
            case 'project':
              element = <Project key={Math.random()} block={block} />
              break
            case 'testimonial':
              element = <Testimonial key={Math.random()} block={block} />
              break
            case 'cta':
              element = <CTA key={Math.random()} block={block} />
              break
            case 'ribbon':
              element = <Ribbon key={Math.random()} block={block} />
              break
            case 'contact':
              element = <Contact key={Math.random()} block={block} />
              break
            default:
              element = ''
              break
          }
          if (block.raised) {
            return (
              <div key={Math.random()}>
                <div>{element}</div>
              </div>
            )
          } else {
            return element
          }
        })}
      </Layout>
    )
  }
}

BlocksTemplate.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(landingPageStyle)(BlocksTemplate)

export const pageQuery = graphql`
  query BlockPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        blocks {
          component
          image
          title
          subtitle
          description
          button_text
          background_color
          imagegallery
          col1 {
            title
            subtitle
            icon
            icon_color
            name
            description
            social_link
            image
            email
            email2
          }
          col2 {
            title
            subtitle
            icon
            icon_color
            name
            description
            social_link
            image
            telephone
            fax
          }
          col3 {
            title
            subtitle
            icon
            icon_color
            name
            description
            social_link
            image
            address_line_1
            address_line_2
          }
          icon
          icon_color
          image_alignment
          raised
        }
      }
    }
  }
`
