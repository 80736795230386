import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
`
const BannerText = styled.h1`
  font-size: 90px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  @media (max-width: 1200px) {
    font-size: 75px;
  }
  @media (max-width: 900px) {
    font-size: 65px;
  }
  @media (max-width: 700px) {
    font-size: 45px;
  }
`

const SubWrapper = styled.div`
  width: 50%;
  @media (max-width: 700px) {
    font-size: 70%;
  }
  @media (max-width: 600px) {
    font-size: 80%;
  }
  @media (max-width: 500px) {
    font-size: 90%;
  }
`

const pink = '#ff0066'
const orange = '#ff5a00'
const yellow = '#ff9000'

class Banner extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  }
  render() {
    return (
      <Wrapper>
        <SubWrapper>
          <BannerText>
            THE WORLD’S BEST FREELANCE TECHNOLOGY TALENT AVAILABLE ON DEMAND.
          </BannerText>
        </SubWrapper>
      </Wrapper>
    )
  }
}

Banner.propTypes = {
  classes: PropTypes.object,
}

export default Banner
