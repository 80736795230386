import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const pink = '#ff0066'
const orange = '#ff5a00'
const yellow = '#ff9000'
const gray = '#232323'

const Wrapper = styled.div`
  padding: 5% 0 0 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${gray};
`
const ContactHeader = styled.h1`
  text-align: center;
  font-size: 100px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0 10px;
  @media (max-width: 1275px) {
    font-size: 70px;
  }
  @media (max-width: 890px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 29px;
  }
`

const ColWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
`

const ContactText = styled.p`
  color: white;
  font-size: 18px;
  font-weight: bold;
`

const StyledLink = styled(props => <Link {...props} />)`
  text-transform: uppercase;
  color: #ff0066;
  text-emphasis: none;
  font-weight: bold;
  width: 90%;
  font-size: 28px;
  :hover {
    color: #ff9000;
  }
`

const EmailLink = styled.a`
  font-size: 28px;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: bold;
  color: #ff0066;
  background-color: transparent;
  border: none;
`

class Contact extends React.Component {
  render() {
    const { block } = this.props
    return (
      <Wrapper style={{ backgroundColor: block.background_color }}>
        <ContactHeader>
          <span style={{ color: pink }}>GET </span>
          <span style={{ color: orange }}>IN </span>
          <span style={{ color: yellow }}>TOUCH</span>
        </ContactHeader>
        <ColWrap>
          <ContactText>
            You can either email us direct on{' '}
            <EmailLink href="mailto:hello@flexatal.com">
              {block.col1.email}{' '}
            </EmailLink>{' '}
            or use our handy contact form{' '}
            <StyledLink to="/contact">HERE</StyledLink>
          </ContactText>
        </ColWrap>
      </Wrapper>
    )
  }
}

Contact.propTypes = {
  classes: PropTypes.object,
}

export default Contact
