import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from 'components/CustomButtons/Button.jsx'

const Wrapper = styled.div`
  background: #ff9000;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const StudyHeader = styled.h1`
  text-align: center;
  font-size: 100px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0 10px;
  @media (max-width: 1275px) {
    font-size: 70px;
  }
  @media (max-width: 890px) {
    font-size: 50px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 29px;
  }
`
const StudyText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 40%;
  margin: auto;
  padding: 30px 0 0;
`

const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: #ff9000;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 1vh;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  padding: 5% 5%;
  flex-direction: column;
  width: 40%;
  margin: auto;
  @media (max-width: 1000px) {
    width: 45%;
  }
  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
`

class Testimonial extends React.Component {
  render() {
    return (
      <Wrapper>
        <StudyHeader>Case Studies</StudyHeader>

        <StudyText>
          Hundreds of clients have delivered their projects with the help of
          Flexatal. Here are just a few of our case studies.
        </StudyText>

        <ButtonWrap>
          <Button
            onClick={() => navigate('/cases')}
            round
            color="success"
            size="lg"
          >
            <ButtonText>TAKE A LOOK</ButtonText>
          </Button>
        </ButtonWrap>
      </Wrapper>
    )
  }
}

Testimonial.propTypes = {
  classes: PropTypes.object,
}

export default Testimonial
